<template>
  <div class="registerContainer">
    <Navbar navTitle="ADMIN LOGIN"></Navbar>
    <main>
      <div class="formContainer">
        <van-form >
          <div style="height: 20px"></div>
          <van-field
            v-model="form.user"
            center
            clearable
            label="用户名"
            placeholder="请输入用户名"
            name="password"
          >
          </van-field>
          <div style="height: 20px"></div>
          
          <van-field
            v-model="form.password"
            center
            clearable
            type="password"
            label="密码"
            placeholder="请输入密码"
            name="password"
          >
          </van-field>
          
          <div style="margin: 56px 0 16px;">
            <van-button :loading="submitLoading" @click="onSubmit" round block type="info" native-type="submit">登录</van-button>
          </div>
        </van-form>
      </div>
      
    </main>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken';
import PhoneInput from '@/components/PhoneInput/PhoneInput'
export default {
  components: {
    PhoneInput
  },
  data() {
    return {
      submitLoading: false,
      form: {
        user: '',
        password: '',
      }
    }
  },
  methods: {
    t(params) {
      return this.$t(`login.${params}`)
    },
    onSubmit(e) {
      if (!this.form.user) {
        return this.$toast('请输入用户名')
      }
      if (!this.form.password) {
        return this.$toast('请输入密码')
      }
      this.submitLoading = true
      this.$fetch({
        url: '/admin/signIn',
        method: 'POST',
        data: {
          user: this.form.user,
          password: encryptWithPublicKey(this.form.password),
        }
      }).then(res => {
        console.log(res)
        this.$toast.success('登录成功')
        const token = res.token.replace('Bearer ', '')
        const tokenData = jwt.decode(token)
        window.localStorage.setItem('adminToken', token)
        window.localStorage.setItem('adminTokenData', JSON.stringify(tokenData))
        this.$router.push("/admin/index")
      }).finally(() => {
        this.submitLoading = false
      })
    }
  }
}
</script>
<style>
html, body, #app {
  height: 100%
}
</style>
<style lang="less" scoped>
.registerContainer {
  min-height: 100%;
  background: #3F3F3F;
  main {
    padding: 0 10px;
  }
}
.formContainer {
  margin: 30px auto 0;
  width: 100%;
  max-width: 600px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}
@media screen and (max-width: 500px) {
  .formContainer {
    padding: 30px 10px;
  }
}
</style>
